@import "./partials/_mixins.scss";
@import "./partials/_vars.scss";

.load {
    height: 100vh;
    width: 100vw;
    background: white;
}

.full-width {
    width: 100%;
}

.forgot-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #0000EE;
    cursor: pointer;
    font-size: 16px;
}

.login {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(to top right, #0288D1 40%, #d6e7ff 100%);
}

.login-box-container {
    height: 100vh;
    width: 100vw;
}

.login-box {
    background: white;
    min-height: 50vh;
    height: auto;
    width: 25vw;
    padding: 2% 0;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 2px;
}

.login-body {
    height: 45%;
}

.login-button-container {
    padding-top: 5%;
    width: 100%;

    .login-button {
        margin-bottom: 10px;
    }
}

// Logo

.header-container {
    height: 25%;
    padding: 2% 0;
}

.header {
    display: block;
    font-size: calc(1em + 1.2vw);
    font-family: Roboto;
    font-weight: 600;
    color: $lh-blue;
    text-shadow: 2px 2px 5px $gray-4;
    letter-spacing: .02em;
}

.lighthouse-logo-container {
    width: 25vw;
    max-width: 25vw;
    height: 100%;
}

.lighthouse-logo {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;

    img {
        width: 65%;
        min-width: 253px;
    }
}

// Login Box
.input-container {
    margin: 8% 0 11% 0;
}

.input-color {
    border-color: green;
    width: 100%;
}

.form-box {
    width: 65%;
}

.login-input {
    width: 100%;
}

.login-button {
    padding-top: 2%;
    background-color: rgba(2, 136, 209, 0.94) !important;
    width: 100%;
}

.login-button-label {
    text-transform: none;
    font-size: 1.3em;
    letter-spacing: .05em;
    color: #FFFFFF;
}

// error display
.error-messages {
    color: red;
    padding: 2%;
}

// Forgot password box
.password-reset-box {
    padding-top: 2%;
    height: 20%;

    a {
        text-decoration: none;
    }
}

.password-reset-text {
    padding: 0 2% 1% 2%;
    font-size: 12px;
}

// Footer
.footer-box {
    height: 10%;
    padding: 2%;
}

.aw-logo {
    max-width: 100%;
    height: auto;

    img {
        width: 50%;
    }
}

// Right Side Client Portal text message
.cp-message {
    color: white;
    padding: 0 10%;

    a {
        color: white;
    }
}

.success-message {
    font-size: 22px;
    font-weight: 500;
    color: #0288D1;
}

.redirecting {
    margin-top: 10%;
}

.dot-loader-success {
    padding-top: 15%;
}
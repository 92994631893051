@import "./partials/_mixins.scss";
@import "./partials/_vars.scss";

.uploading {
  font-size:20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #00ACC1;
  min-height: 5vh;
}

// Animation for loading
.uploading .lens {
  font-size: 8px;
  padding: 3px;
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.uploading .lens:nth-child(2) {
  animation-delay: .2s;
}

.uploading .lens:nth-child(3) {
  animation-delay: .4s;
}

.uploading .lens:nth-child(4) {
  animation-delay: .6s;
}

.uploading .lens:nth-child(5) {
  animation-delay: .8s;
}

@keyframes blink {
  0% {
  opacity: .2;
  }
  30% {
  opacity: 1;
  }
  100% {
  opacity: .2;
  }
}


@import "./color-palette";
// FONT STYLES (Weight, Size, Sans)
$font-light: 100;
$font-normal: 400;
$font-bold: 600;

// BREAKPOINTS (Responsive Design)
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 991px;
$screen-lg: 1200px;
$screen-xl: 1880px;

/* // Z-INDEX MAGIC // */
$z-indexed-items: 'loading-indicator', 'floating-btn','filtering-sidebar', 'ace-editor', 'module-navbar', 'nav-bar-brand', 'topnavbar';
$z-index-map: ();
$z-ground: 0;
$z-ceil: 100;
$counter: 1;

@each $item in $z-indexed-items {
  $z-index-map: map-merge($z-index-map, ($item: $counter));
  $counter: $counter+1;
}

@mixin z($key) {
  z-index: map-get($z-index-map, $key);
}

// COLORS
// THOUGHTS: Lets try to be consistent with our colors and not overdo it when it comes to the million different options in our color palette
// Is it necessary to have more than 5 colors per module? how many tones of gray do we need?

// TODO: Define color presets that complement each other for further consistency (ie: gray on white, white on blue)
// "primary-content-1 should only be used with secondary-content-1 and bg-1" = 2 variations of light text on a dark BG

// The Almighty Lighthouse Logo blue
$lh-blue: #5195fb;

// Another lh blue for background:
$lh-blue-dk: #0288D1;

// Lighthouse logo colors
$lh-logo-lt: #8CBAFF;
$lh-logo-med: #569AFF;
$lh-logo-dk: #3083FF;

$aw-logo-green: #9eb60d;

/* Default colors */
$button-primary: $indigo-5;
$button-cancel: $orange-9;
/* Font Colors */

/*** // MODULE BRAND COLORS // ***/

/* Navigation bar color */
$ad-nav: $deepPurple-5;
$portal-nav: $lightblue-7;
$projects-nav: $lightblue-7;

// Portal - Default Home Color
$portal-white: $lightblue-1;
$portal-light: $lightblue-3;
$portal-med: $lightblue-7;
$portal-dark: $lightblue-8;
$portal-black: $lightblue-9;

/* portal accent colors */
$portal-A1: $lightblue-0;
$portal-A2: $lightblue-A2;
$portal-A4: $lightblue-A4;
$portal-A7: $lightblue-A7;

// Admin
$admin-white: $cyan-1;
$admin-light: $cyan-3;
$admin-med: $cyan-7;
$admin-dark: $cyan-8;
$admin-black: $cyan-9;

$admin-A1: $cyan-A1;
$admin-A2: $cyan-A2;
$admin-A4: $cyan-A4;
$admin-A7: $cyan-A7;

// Projects
$project-white: $deepPurple-1;
$project-light: $deepPurple-3;
$project-med: $deepPurple-5;
$project-dark: $deepPurple-7;
$project-black: $deepPurple-9;

$project-A1: $cyan-A1;
$project-A2: $cyan-A2;
$project-A4: $cyan-A4;
$project-A7: $lightblue-7;

// Profile
$profile-white: $indigo-1;
$profile-light: $indigo-3;
$profile-med: $indigo-5;
$profile-dark: $indigo-6;
$profile-black: $indigo-9;

$profile-A1: $cyan-A1;
$profile-A2: $cyan-A2;
$profile-A4: $cyan-A4;
$profile-A7: $lightblue-7;

@at-root
/* // MEANINGFUL COLORS // */
// Status
$error-red: #9F260D;
$error-yellow: $yellow-8;
$mat-error:  #ff6e40;
$success-green: #4BAC17;
$success-blue: #0066cc;
// Buttons
$hyperlink-blue: #337ab7;
$info-green:#A3E282;
$edit-orange: #CD6818;
// Background
$bg-gray-light: #f9f9f9;
$bg-gray-med: $gray-1;
$bg-gray-dark: #D8D8D8;
$bg-gray-black: $gray-9;
// Content
// TODO: Figure out a better name than 'semiblack'
$content-gray-white: $gray-1;
$content-gray-light: $gray-4;
$content-gray-med: $gray-5;
$content-gray-dark: $gray-7;
$content-gray-semiblack: $gray-8;
$content-gray-black: $gray-9;
// Border
$border-gray-light: $gray-1;
$border-gray-med: $gray-3;
$border-gray-dark: $gray-9;
// Divider
$divider-gray-light: $gray-1;
$divider-gray-med: $gray-3;
$divider-gray-dark: $gray-3;
// Inactive (Muted/Disabled)
$inactive-gray-light: $gray-3;
$inactive-gray-med: $gray-5;
$inactive-gray-dark: $gray-6;
// Table
$table-row-gray: $gray-1;
$table-border-light: $gray-1;
$table-border-gray: $gray-2;
$table-border-black: $gray-8;
// Box Shadows and stuff like that
$shadow-light: $gray-6;
$shadow-med: $gray-8;
$shadow-dark: $gray-9;

/* // One Off Colors // */
// White/Black
$white: #ffffff;
$black: #000000;

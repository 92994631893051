// WAIT WAIT WAIT, BEFORE YOU EDIT THIS FILE...
// This colour palette is a master list of colors we want to use in our app
// While none of these colors are necessarily set in stone cuz we copypastad it from Material,
// introducing a new color should be planned and very deliberate.

// Ideally, use the variables in `_vars.scss` that pull these colors for what you need and leave these as untouched as possible.
// Try to mirror this as closely to the colors available in `color.service.ts`

// Material Color Palette
// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

$red-0: #FFEBEE;
$red-1: #ffcdd2;
$red-2: #ef9a9a;
$red-3: #e57373;
$red-4: #ef5350;
$red-5: #f44336;
$red-6: #e53935;
$red-7: #d32f2f;
$red-8: #c62828;
$red-9: #b71c1c;

$red-A1: #ff8a80;
$red-A2: #ff5252;
$red-A4: #ff1744;
$red-A7: #d50000;

$pink-0: #FCE4EC;
$pink-1: #f8bbd0;
$pink-2: #f48fb1;
$pink-3: #f06292;
$pink-4: #ec407a;
$pink-5: #e91e63;
$pink-6: #d81b60;
$pink-7: #c2185b;
$pink-8: #ad1457;
$pink-9: #880e4f;

$pink-A1: #FF80AB;
$pink-A2: #FF4081;
$pink-A4: #F50057;
$pink-A7: #C51162;

$purple-0: #F3E5F5;
$purple-1: #e1bee7;
$purple-2: #ce93d8;
$purple-3: #ba68c8;
$purple-4: #ab47bc;
$purple-5: #9c27b0;
$purple-6: #8e24aa;
$purple-7: #7b1fa2;
$purple-8: #6a1b9a;
$purple-9: #4a148c;

$purple-A1: #EA80FC;
$purple-A2: #E040FB;
$purple-A4: #D500F9;
$purple-A7: #AA00FF;

$deepPurple-0: #ede7f6;
$deepPurple-1: #d1c4e9;
$deepPurple-2: #b39ddb;
$deepPurple-3: #9575cd;
$deepPurple-4: #7e57c2;
$deepPurple-5: #5F5393;  // Using custom `purple-base`, original material purple-5 is #673ab7
$deepPurple-6: #5e35b1;
$deepPurple-7: #512da8;
$deepPurple-8: #4527a0;
$deepPurple-9: #311b92;

$deepPurple-A1: #B388FF;
$deepPurple-A2: #7C4DFF;
$deepPurple-A4: #651FFF;
$deepPurple-A7: #6200EA;

$indigo-0: #E8EAF6;
$indigo-1: #c5cae9;
$indigo-2: #9fa8da;
$indigo-3: #7986cb;
$indigo-4: #5c6bc0;
$indigo-5: #3f51b5;
$indigo-6: #3949ab;
$indigo-7: #303f9f;
$indigo-8: #283593;
$indigo-9: #1a237e;

$indigo-A1: #8C9EFF;
$indigo-A2: #536DFE;
$indigo-A4: #3D5AFE;
$indigo-A7: #304FFE;

$blue-0: #E3F2FD;
$blue-1: #e3f2fd;
$blue-2: #bbdefb;
$blue-3: #90caf9;
$blue-4: #64b5f6;
$blue-5: #42a5f5;
$blue-6: #2196f3;
$blue-7: #1e88e5;
$blue-8: #1976d2;
$blue-9: #1565c0;

$blue-A1: #82B1FF;
$blue-A2: #448AFF;
$blue-A4: #2979FF;
$blue-A7: #2962FF;

$lightblue-0: #E1F5FE;
$lightblue-1: #b3e5fc;
$lightblue-2: #81d4fa;
$lightblue-3: #4fc3f7;
$lightblue-4: #29b6f6;
$lightblue-5: #03a9f4;
$lightblue-6: #039be5;
$lightblue-7: #0288d1;
$lightblue-8: #0277bd;
$lightblue-9: #01579b;

$lightblue-A1: #80D8FF;
$lightblue-A2: #40C4FF;
$lightblue-A4: #00B0FF;
$lightblue-A7: #0091EA;

$cyan-0: #E0F7FA;
$cyan-1: #b2ebf2;
$cyan-2: #80deea;
$cyan-3: #4dd0e1;
$cyan-4: #26c6da;
$cyan-5: #00bcd4;
$cyan-6: #00acc1;
$cyan-7: #0097a7;
$cyan-8: #00838f;
$cyan-9: #006064;

$cyan-A1: #84FFFF;
$cyan-A2: #18FFFF;
$cyan-A4: #00E5FF;
$cyan-A7: #00B8D4;

$teal-0: #e0f2f1;
$teal-1: #b2dfdb;
$teal-2: #80cbc4;
$teal-3: #4db6ac;
$teal-4: #26a69a;
$teal-5: #009688;
$teal-6: #00897b;
$teal-7: #00796b;
$teal-8: #00695c;
$teal-9: #004d40;

$green-0: #E8F5E9;
$green-1: #c8e6c9;
$green-2: #a5d6a7;
$green-3: #81c784;
$green-4: #66bb6a;
$green-5: #4caf50;
$green-6: #43a047;
$green-7: #388e3c;
$green-8: #2e7d32;
$green-9: #1b5e20;

$yellow-0: #FFFDE7;
$yellow-1: #fff9c4;
$yellow-2: #fff59d;
$yellow-3: #fff176;
$yellow-4: #ffee58;
$yellow-5: #ffeb3b;
$yellow-6: #fdd835;
$yellow-7: #fbc02d;
$yellow-8: #f9a825;
$yellow-9: #f57f17;

$amber-0: #FFF8E1;
$amber-1: #ffecb3;
$amber-2: #ffe082;
$amber-3: #ffd54f;
$amber-4: #ffca28;
$amber-5: #ffc107;
$amber-6: #ffb300;
$amber-7: #ffa000;
$amber-8: #ff8f00;
$amber-9: #ff6f00;

$orange-0: #FFF3E0;
$orange-1: #ffe0b2;
$orange-2: #ffcc80;
$orange-3: #ffb74d;
$orange-4: #ffa726;
$orange-5: #ff9800;
$orange-6: #fb8c00;
$orange-7: #f57c00;
$orange-8: #ef6c00;
$orange-9: #e65100;

$orange-A1: #FFD180;
$orange-A2: #FFAB40;
$orange-A4: #FF9100;
$orange-A7: #FF6D00;

$deepOrange-0: #fbe9e7;
$deepOrange-1: #ffccbc;
$deepOrange-2: #ffab91;
$deepOrange-3: #ff8a65;
$deepOrange-4: #ff7043;
$deepOrange-5: #ff5722;
$deepOrange-6: #f4511e;
$deepOrange-7: #e64a19;
$deepOrange-8: #d84315;
$deepOrange-9: #bf360c;

$deepOrange-A1: #ff9e80;
$deepOrange-A2: #ff6e40;
$deepOrange-A4: #ff3d00;
$deepOrange-A7: #dd2c00;

$brown-0: #EFEBE9;
$brown-1: #d7ccc8;
$brown-2: #bcaaa4;
$brown-3: #a1887f;
$brown-4: #8d6e63;
$brown-5: #795548;
$brown-6: #6d4c41;
$brown-7: #5d4037;
$brown-8: #4e342e;
$brown-9: #3e2723;

$gray-0: #FAFAFA;
$gray-1: #efefef;
$gray-2: #b7b7b7;
$gray-3: #e0e0e0; // #ccc
$gray-4: #bdbdbd; // #7A7A7A or 66666e
$gray-5: #9e9e9e; // #9999a1
$gray-6: #757575; // 66666a
$gray-7: #616161;
$gray-8: #333333;
$gray-9: #2b2b2b;

$bluegray-0: #ECEFF1;
$bluegray-1: #CFD8DC;
$bluegray-2: #B0BEC5;
$bluegray-3: #90A4AE;
$bluegray-4: #78909C;
$bluegray-5: #607D8B;
$bluegray-6: #546E7A;
$bluegray-7: #455A64;
$bluegray-8: #37474F;
$bluegray-9: #263238;
